import React, { FC, PropsWithChildren } from 'react';
import { QuotePageRefresh } from './QuotePageRefreshProvider';
import { Theme } from './ThemeContext';

export const Providers: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Theme>
      <QuotePageRefresh>{children}</QuotePageRefresh>
    </Theme>
  );
};
