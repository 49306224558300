import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { Link } from 'components';

export const Footer: FC = () => {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', md: 180 },
                overflow: 'hidden',
                alignItems: { xs: 'center', md: 'flex-start' },
                flexDirection: { xs: 'column', md: 'row' },
                mb: 4,
              }}
            >
              <Box width={120} component={Link} href="/" title="BeeHive">
                <StaticImage
                  loading="eager"
                  src="../../../../static/images/logo/beehive-logo.png"
                  alt="BeeHive Logo"
                  placeholder="blurred"
                />
              </Box>
            </Box>
            <List>
              <ListItem
                disableGutters
                sx={{
                  pt: 0,
                  justifyContent: { xs: 'center', md: 'flex-start' },
                }}
              >
                <ListItemAvatar
                  sx={{
                    mr: 2,
                    ml: { xs: 2, md: 0 },
                    minWidth: 'auto !important',
                  }}
                >
                  <Avatar
                    component={MuiLink}
                    href="https://www.facebook.com/people/BeeHive-USA/61550811675354/"
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <FacebookIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemAvatar
                  sx={{
                    mr: 2,
                    ml: { xs: 2, md: 0 },
                    minWidth: 'auto !important',
                  }}
                >
                  <Avatar
                    component={MuiLink}
                    href="https://twitter.com/BeeHiveUSA"
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <TwitterIcon />
                  </Avatar>
                </ListItemAvatar>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={6} container>
            <Grid item xs={12}>
              <Box sx={{ mb: 2 }}>
                <Typography color="text.primary" variant="h5">
                  Services
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Link
                underline="none"
                href="/get-a-quote"
                color="text.primary"
                variant={'subtitle2'}
              >
                Voice & Internet
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Link
                underline="none"
                href="/get-a-quote"
                color="text.primary"
                variant={'subtitle2'}
              >
                Conversational AI
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Link
                underline="none"
                href="/get-a-quote"
                color="text.primary"
                variant={'subtitle2'}
              >
                Chatbot Development
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Link
                underline="none"
                href="/get-a-quote"
                color="text.primary"
                variant={'subtitle2'}
              >
                Web 3.0
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Link
                underline="none"
                href="/get-a-quote"
                color="text.primary"
                variant={'subtitle2'}
              >
                Digital Marketing
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <Link
                underline="none"
                href="/get-a-quote"
                color="text.primary"
                variant={'subtitle2'}
              >
                Business Consulting
              </Link>
            </Grid>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box sx={{ mb: 2 }}>
              <Link
                underline="none"
                color="text.primary"
                component={Link}
                target="blank"
                href="/contact"
                variant="h5"
              >
                Contact
              </Link>
            </Box>
            <List sx={{ pt: 0 }}>
              <ListItem disableGutters sx={{ pt: 0 }}>
                <ListItemAvatar sx={{ mr: 2, minWidth: 'auto !important' }}>
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <svg
                      width={20}
                      height={20}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary="5940 S Rainbow Blvd Ste 400"
                  secondary="Las Vegas, Nevada 89118"
                  sx={{ mt: 0 }}
                />
              </ListItem>
              <ListItem disableGutters sx={{ pt: 0 }}>
                <ListItemAvatar sx={{ mr: 2, minWidth: 'auto !important' }}>
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <svg
                      width={20}
                      height={20}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <MuiLink
                      href="mailto:info@beehiveusa.org"
                      underline="none"
                      color="text.primary"
                    >
                      info@beehiveusa.org
                    </MuiLink>
                  }
                />
              </ListItem>
              <ListItem disableGutters sx={{ pt: 0 }}>
                <ListItemAvatar sx={{ mr: 2, minWidth: 'auto !important' }}>
                  <Avatar
                    sx={{
                      width: 40,
                      height: 40,
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <svg
                      width={20}
                      height={20}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                    </svg>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <MuiLink
                      href="tel:+17753659413"
                      underline="none"
                      color="text.primary"
                    >
                      775-365-9413
                    </MuiLink>
                  }
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
          sx={{ pt: 5 }}
        >
          &copy; BeeHive. 2023. All rights reserved{' - '}
          <Link
            underline="none"
            href="/privacy-policy"
            color="text.primary"
            variant={'subtitle2'}
          >
            Privacy Policy
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};
