module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-theme-material-ui-top-layout@5.2.0_@mui+material@5.14.9_gatsby@5.12.4/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.11.1_gatsby@5.12.4/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.12.0_gatsby@5.12.4_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-YHC25443CY"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.4_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.2.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
