import { graphql, HeadProps, useStaticQuery } from 'gatsby';
import React, { FC, PropsWithChildren } from 'react';

export interface SEOProps extends HeadProps {
  title?: string;
}

interface SiteStaticQuery {
  site: {
    siteMetadata: {
      title: string;
    };
  };
}

export const SEO: FC<PropsWithChildren<SEOProps>> = ({ children, title }) => {
  const data = useStaticQuery<SiteStaticQuery>(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const siteTitle = data.site.siteMetadata.title;

  return (
    <>
      <html lang="en" />
      <title>
        {title && `${title} | `}
        {siteTitle}
      </title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ffc40d" />
      <meta name="msapplication-TileColor" content="#ffc40d" />
      <meta name="theme-color" content="#ffffff" />
      {children}
    </>
  );
};
