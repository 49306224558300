import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { FC, ReactNode } from 'react';

export interface ConsultingStoryProps {
  title: ReactNode;
  story: string[];
}

export const ConsultingStory: FC<ConsultingStoryProps> = (props) => {
  const { title, story } = props;
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          {title}
        </Grid>
        <Grid item xs={12} md={6}>
          {story.map((s, i) => (
            <Typography
              key={`story-section-${i}`}
              sx={{ color: 'text.secondary', fontWeight: 400, mb: 2 }}
            >
              {s}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
