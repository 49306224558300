import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

export const QuotePageRefreshProvider = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>] | null
>(null);

export const useQuotePageRefresh = () => {
  const ctx = useContext(QuotePageRefreshProvider);

  if (!ctx) {
    throw new Error(
      'useQuotePageRefresh should be wrapped by <QuotePageRefreshProvider.Provider />',
    );
  }

  return ctx;
};

export const QuotePageRefresh: FC<PropsWithChildren> = ({ children }) => {
  const value = useState(false);
  return (
    <QuotePageRefreshProvider.Provider value={value}>
      {children}
    </QuotePageRefreshProvider.Provider>
  );
};
