import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import React from 'react';
import { SidebarNav } from './components';
import { TopBarNavItem } from 'components';

interface SidebarProps {
  onClose: VoidFunction;
  open: boolean;
  variant: 'permanent' | 'persistent' | 'temporary' | undefined;
  pages: TopBarNavItem[];
}

export const Sidebar = (props: SidebarProps): JSX.Element => {
  const { pages, open, variant, onClose } = props;
  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 280,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          padding: 1,
        }}
      >
        <SidebarNav pages={pages} />
      </Box>
    </Drawer>
  );
};
