import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import { ThemeProvider } from '@mui/material/styles';
import AOS from 'aos';
import React, { useCallback, useEffect, useState } from 'react';
import {
  defaultThemeMode,
  useGetTheme,
  ThemeMode,
} from 'themes/the-front/theme';

const isValidThemeMode = (themeMode: string): themeMode is ThemeMode => {
  return ([ThemeMode.Dark, ThemeMode.Light] as string[]).includes(themeMode);
};

export const usePage = () => {
  let initialThemeMode = defaultThemeMode;
  try {
    const localTheme = window.localStorage.getItem('themeMode');
    if (localTheme && isValidThemeMode(localTheme))
      initialThemeMode = localTheme;
  } catch {
    // Do nothing
  }
  const [themeMode] = useState<ThemeMode>(initialThemeMode);
  const [mountedComponent, setMountedComponent] = useState(false);

  const setMode = useCallback((mode: ThemeMode) => {
    try {
      window.localStorage.setItem('themeMode', mode);
    } catch {
      /* do nothing */
    }
    // TODO: Fix this behavior
    // setTheme(mode);
    window.location.reload();
  }, []);

  const themeToggle = useCallback((): void => {
    setMode(themeMode === ThemeMode.Light ? ThemeMode.Dark : ThemeMode.Light);
  }, [setMode, themeMode]);

  useEffect(() => {
    setMountedComponent(true);
    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: 'ease-in-out',
    });
  }, []);

  useEffect(() => {
    if (mountedComponent) {
      AOS.refresh();
    }
  }, [mountedComponent, themeMode]);

  // For now just return dark theme
  return [ThemeMode.Dark, themeToggle] as const;
};

export interface PageProps {
  children: React.ReactNode;
}

export const Page = ({ children }: PageProps): JSX.Element => {
  const [themeMode] = usePage();

  return (
    <ThemeProvider theme={useGetTheme(themeMode)}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Paper elevation={0}>{children}</Paper>
    </ThemeProvider>
  );
};
