import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React, { createContext, FC, PropsWithChildren, useContext } from 'react';
import { usePage } from 'themes/the-front/components/Page';
import { useGetTheme } from 'themes/the-front/theme';

export const ThemeToggleContext = createContext<VoidFunction | null>(null);

export const useThemeToggle = () => {
  const ctx = useContext(ThemeToggleContext);

  if (!ctx) {
    throw new Error(
      'useThemeToggle should be wrapped by <ThemeToggleContext.Provider />',
    );
  }

  return ctx;
};

export const Theme: FC<PropsWithChildren> = ({ children }) => {
  const [themeMode, themeToggle] = usePage();
  const theme = useGetTheme(themeMode);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ThemeToggleContext.Provider value={themeToggle}>
        {children}
      </ThemeToggleContext.Provider>
    </ThemeProvider>
  );
};
