import { responsiveFontSizes, Theme } from '@mui/material';
import { ComponentsOverrides, createTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { dark, light } from './palette';
import shadows from './shadows';
import { ThemeMode } from './types';

export * from './types';
export const defaultThemeMode = ThemeMode.Dark;

export const useGetTheme = (mode: ThemeMode = defaultThemeMode): Theme => {
  return useMemo(() => {
    const t = createTheme({
      palette: mode === ThemeMode.Light ? light : dark,
      shadows: shadows(mode),
      typography: {
        fontFamily: '"Inter", sans-serif',
        button: {
          textTransform: 'none',
          fontWeight: 'medium' as React.CSSProperties['fontWeight'],
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1300,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontWeight: 400,
              borderRadius: 5,
              paddingTop: 10,
              paddingBottom: 10,
            },
            containedSecondary:
              mode === ThemeMode.Light ? { color: 'white' } : {},
          } as ComponentsOverrides['MuiButton'],
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
          } as ComponentsOverrides['MuiInputBase'],
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: 5,
            },
            input: {
              borderRadius: 5,
            },
          } as ComponentsOverrides['MuiOutlinedInput'],
        },
        MuiCard: {
          styleOverrides: {
            root: {
              borderRadius: 8,
            },
          } as ComponentsOverrides['MuiCard'],
        },
      },
    });
    return responsiveFontSizes(t);
  }, [mode]);
};
