import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { Link } from 'components';

interface NavItemProps {
  title: string;
  items?: PageItem[];
  to?: string;
  colorInvert?: boolean;
}

export const NavItem = (props: NavItemProps): JSX.Element => {
  const { title, items, to, colorInvert = false } = props;
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const hasActiveLink = () => items?.find((i) => i.href === activeLink);
  const linkColor = colorInvert ? 'common.white' : 'text.primary';

  return (
    <Box>
      {to && !items ? (
        <Link to={to}>
          <ListItem>
            <Typography
              // fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
              color={linkColor}
            >
              {title}
            </Typography>
          </ListItem>
        </Link>
      ) : (
        <Accordion
          disableGutters
          elevation={0}
          sx={{ backgroundColor: 'transparent' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ padding: 0 }}
          >
            <Typography
              fontWeight={hasActiveLink() ? 600 : 400}
              color={hasActiveLink() ? 'primary' : 'text.primary'}
            >
              {title}
            </Typography>
          </AccordionSummary>
          {items && (
            <AccordionDetails sx={{ padding: 0 }}>
              <Grid container spacing={1}>
                {items.map((p, i) => (
                  <Grid item key={i} xs={12}>
                    <Button
                      size={'large'}
                      component={Link}
                      href={p.href}
                      fullWidth
                      sx={{
                        justifyContent: 'flex-start',
                        color:
                          activeLink === p.href
                            ? theme.palette.primary.main
                            : theme.palette.text.primary,
                        backgroundColor:
                          activeLink === p.href
                            ? alpha(theme.palette.primary.main, 0.1)
                            : 'transparent',
                        fontWeight: activeLink === p.href ? 600 : 400,
                      }}
                    >
                      {p.title}
                      {p.isNew && (
                        <Box
                          padding={0.5}
                          display={'inline-flex'}
                          borderRadius={1}
                          bgcolor={'primary.main'}
                          marginLeft={2}
                        >
                          <Typography
                            variant={'caption'}
                            sx={{ color: 'common.white', lineHeight: 1 }}
                          >
                            new
                          </Typography>
                        </Box>
                      )}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          )}
        </Accordion>
      )}
    </Box>
  );
};
