import { GatsbyBrowser, WrapRootElementBrowserArgs } from 'gatsby';
import React, { FC } from 'react';
import { Providers } from 'components';
import 'aos/dist/aos.css';
import './react-slick.css';
import './hubspot.css';

interface AppWrapperProps {
  element: WrapRootElementBrowserArgs['element'];
}

const AppWrapper: FC<AppWrapperProps> = ({ element }) => {
  return <Providers>{element}</Providers>;
};

// eslint-disable-next-line react/prop-types
const WrapWithProvider: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
  return <AppWrapper element={element} />;
};

export default WrapWithProvider;
