import {
  Link as MuiLink,
  type LinkProps as MuiLinkProps,
  Button as MuiButton,
  type ButtonProps as MuiButtonProps,
} from '@mui/material';
import { Link as GatsbyLink, type GatsbyLinkProps } from 'gatsby';
import React, { type ForwardedRef, forwardRef } from 'react';

export type LinkProps<T extends NonNullable<unknown> = NonNullable<unknown>> =
  Omit<MuiLinkProps, 'href'> &
    Omit<GatsbyLinkProps<T>, 'to'> & { to?: string; href?: string };

const AppLink = <T extends NonNullable<unknown>>(
  props: LinkProps<T>,
  ref: ForwardedRef<HTMLAnchorElement>,
) => {
  const { to, href, ...otherProps } = props;
  return (
    <MuiLink
      to={to || href || ''}
      component={GatsbyLink}
      ref={ref}
      {...otherProps}
    />
  );
};

export const Link = forwardRef(AppLink);

export type ButtonProps<T extends NonNullable<unknown> = NonNullable<unknown>> =
  MuiButtonProps &
    Omit<GatsbyLinkProps<T>, 'to'> & { to?: string; href?: string };

const AppButton = <T extends NonNullable<unknown>>(
  props: ButtonProps<T>,
  ref: ForwardedRef<HTMLAnchorElement>,
) => {
  const { to, href, ...otherProps } = props;
  return (
    <MuiButton
      to={to || href || ''}
      component={GatsbyLink}
      ref={ref}
      {...otherProps}
    />
  );
};

export const LinkButton = forwardRef(AppButton);
