import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';
import { Link, LinkProps } from 'components';

export interface TopNavLink extends LinkProps {
  label: ReactNode;
}

interface Props {
  colorInvert?: boolean;
  links?: TopNavLink[];
}

const TopNav = ({ links = [], colorInvert = false }: Props): JSX.Element => {
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      {links.map(({ to, label, ...other }) => (
        <Box key={`TopNavLink-${to}`} marginRight={{ xs: 1, sm: 2 }}>
          <Link
            underline="none"
            to={to}
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{ display: 'flex', alignItems: 'center' }}
            {...other}
          >
            {label}
          </Link>
        </Box>
      ))}
    </Box>
  );
};

export default TopNav;
