import { colors } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { Link } from 'components';

export interface ServicesProps {
  services: Service[];
}

export interface Service {
  title: string;
  subtitle?: string;
  items: string[];
  icon: FC;
  href?: string;
  className?: string;
}

export const Services: FC<ServicesProps> = (props): JSX.Element => {
  const { services } = props;

  return (
    <Box>
      <Grid container spacing={4}>
        {(services || []).map((service) => {
          const {
            title,
            subtitle,
            icon: Icon,
            items,
            href,
            className,
          } = service;
          return (
            <Grid
              key={`service-${title}`}
              item
              xs={12}
              md={6}
              sx={{ marginTop: 2 }}
            >
              <Box
                component={href ? Link : MuiLink}
                underline="none"
                href={href}
                className={className}
                sx={{ color: 'text.primary', cursor: 'pointer' }}
              >
                <Box marginBottom={2}>
                  <Avatar
                    sx={{
                      backgroundColor: colors.red[100],
                      color: colors.red[900],
                      marginBottom: 1,
                      width: 60,
                      height: 60,
                    }}
                  >
                    <Box
                      component={'svg'}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      width={24}
                      height={24}
                    >
                      <Icon />
                    </Box>
                  </Avatar>
                  <Typography variant={'h6'} fontWeight={700} gutterBottom>
                    {title}
                  </Typography>
                  {subtitle && (
                    <Typography color={'text.secondary'}>{subtitle}</Typography>
                  )}
                </Box>
              </Box>
              <Grid container spacing={2}>
                {items.map((item, index) => (
                  <Grid item xs={12} key={index}>
                    <Box
                      component={href ? Link : MuiLink}
                      underline="none"
                      href={href}
                      className={className}
                      sx={{ color: 'text.primary', cursor: 'pointer' }}
                    >
                      <Card
                        variant="outlined"
                        sx={{
                          backgroundColor: 'transparent',
                          '&:hover': { boxShadow: 2 },
                        }}
                      >
                        <CardContent>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: { xs: 'column', sm: 'row' },
                              flex: '1 1 100%',
                              justifyContent: { sm: 'space-between' },
                              alignItems: { sm: 'center' },
                            }}
                          >
                            <Typography
                              fontWeight={700}
                              sx={{ marginBottom: { xs: 1, sm: 0 } }}
                            >
                              {item}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
