import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { NavItem } from './components';
import { Link, LinkButton, ButtonProps } from 'components';

export interface TopBarProps {
  onSidebarOpen: VoidFunction;
  colorInvert?: boolean;
  items: TopBarNavItem[];
}

export interface TopBarNavItem {
  title: string;
  to: string;
  id: string;
  items?: PageItem[];
  button?: Omit<ButtonProps, 'ref'>;
}

export const TopBar: FC<TopBarProps> = (props) => {
  const { onSidebarOpen, colorInvert = false, items } = props;
  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Link
        display={'flex'}
        to="/"
        title="BeeHive"
        width={{ xs: 100, md: 120 }}
      >
        <StaticImage
          loading="eager"
          src="../../../../static/images/logo/beehive-logo.png"
          alt="BeeHive Logo"
          placeholder="blurred"
        />
      </Link>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {(items || []).map((item, index) => (
          <Box
            key={`nav-item-${item.id}`}
            marginLeft={index > 0 ? 4 : undefined}
          >
            {item.button ? (
              <LinkButton {...item.button} href={item.to}>
                {item.title}
              </LinkButton>
            ) : (
              <NavItem
                title={item.title}
                id={item.id}
                to={item.to}
                items={item.items}
                colorInvert={colorInvert}
              />
            )}
          </Box>
        ))}
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};
