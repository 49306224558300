import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StaticImage } from 'gatsby-plugin-image';
import React, { CSSProperties, FC } from 'react';

const style: CSSProperties = {
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  filter: 'brightness(0.7)',
  cursor: 'poiner',
  borderRadius: 8,
};

const photos = [
  {
    photo: (
      <StaticImage
        src={`../../../../static/images/consulting/IMG_1796.jpg`}
        alt="Brandom 1"
        style={style}
      />
    ),
    rows: 2,
    cols: 1,
  },
  {
    photo: (
      <StaticImage
        src={`../../../../static/images/consulting/IMG_1795.jpg`}
        alt="Brandom 2"
        style={style}
      />
    ),
    rows: 1,
    cols: 2,
  },

  {
    photo: (
      <StaticImage
        src={`../../../../static/images/consulting/IMG_8830.jpeg`}
        alt="Brandom 3"
        style={style}
      />
    ),
    rows: 2,
    cols: 1,
  },
  {
    photo: (
      <StaticImage
        src={`../../../../static/images/consulting/IMG_7832.jpg`}
        alt="Brandom 4"
        style={style}
      />
    ),
    rows: 2,
    cols: 1,
  },

  {
    photo: (
      <StaticImage
        src={`../../../../static/images/consulting/IMG_1794.jpg`}
        alt="Brandom 5"
        style={style}
      />
    ),
    rows: 1,
    cols: 1,
  },
];

export const ConsultingGallery: FC = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <ImageList
        variant="quilted"
        cols={3}
        rowHeight={isMd ? 300 : 200}
        gap={isMd ? 16 : 4}
      >
        {photos.map((item, i) => (
          <ImageListItem
            key={`consulting-gallery-${i}`}
            cols={item.cols}
            rows={item.rows}
          >
            {item.photo}
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};
