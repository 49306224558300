import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import React, { FC, useCallback, useState } from 'react';
import { Footer } from './Footer';
import { Sidebar } from './Sidebar';
import { TopBar, TopBarNavItem } from 'components';
import { Container } from 'themes/the-front/components';
import TopNav from 'themes/the-front/components/TopNav';

export interface MainProps {
  children: React.ReactNode;
  colorInvert?: boolean;
  bgcolor?: string;
  onlyLogo?: boolean;
}

const pages: TopBarNavItem[] = [
  { title: 'Home', id: 'home-link', to: '/' },
  { title: 'Affiliates', id: 'affiliates-section', to: '/#affiliates' },
  { title: 'Consulting', id: 'consulting-page', to: '/consulting' },
  { title: 'Blog', id: 'blog-link', to: '/blog' },
  {
    title: 'Get a Quote',
    id: 'get-a-quote-link',
    to: '/get-a-quote',
    button: { variant: 'contained', color: 'primary', size: 'large' },
  },
];

export const Main: FC<MainProps> = (props) => {
  const {
    children,
    colorInvert = false,
    bgcolor = 'transparent',
    onlyLogo,
  } = props;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = useCallback((): void => {
    setOpenSidebar(true);
  }, []);

  const handleSidebarClose = useCallback((): void => {
    setOpenSidebar(false);
  }, []);

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  return (
    <Box>
      {!onlyLogo && (
        <Box
          bgcolor={bgcolor}
          position={'relative'}
          zIndex={theme.zIndex.appBar}
        >
          <Container
            paddingTop={'8px !important'}
            paddingBottom={'0 !important'}
          >
            <TopNav colorInvert={colorInvert} links={[]} />
          </Container>
        </Box>
      )}
      <AppBar
        position={'sticky'}
        sx={{
          top: 0,
          backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
        }}
        elevation={trigger ? 1 : 0}
      >
        <Container paddingY={1}>
          <TopBar
            onSidebarOpen={handleSidebarOpen}
            colorInvert={trigger ? false : colorInvert}
            items={onlyLogo ? [] : pages}
          />
        </Container>
      </AppBar>
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        {children}
        <Divider />
      </main>
      <Container paddingY={4}>
        <Footer />
      </Container>
    </Box>
  );
};
