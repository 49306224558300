import Box from '@mui/material/Box';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { NavItem } from './NavItem';
import { Link, LinkButton, TopBarNavItem } from 'components';

interface SidebarNavProps {
  pages: TopBarNavItem[];
}

export const SidebarNav = (props: SidebarNavProps): JSX.Element => {
  const { pages } = props;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Link
          href="/"
          title="BeeHive"
          sx={{
            display: 'flex',
            width: { xs: 100, md: 120 },
          }}
        >
          <StaticImage
            loading="eager"
            src="../../../../../static/images/logo/beehive-logo.png"
            alt="BeeHive Logo"
            placeholder="blurred"
          />
        </Link>
      </Box>
      <Box paddingX={2} paddingY={2}>
        {(pages || []).map((item) => (
          <Box
            key={`sidebar-nav-item-${item.id}`}
            marginTop={item.button ? 2 : undefined}
          >
            {item.button ? (
              <LinkButton
                size="large"
                fullWidth
                {...item.button}
                href={item.to}
              >
                {item.title}
              </LinkButton>
            ) : (
              <NavItem title={item.title} to={item.to} items={item.items} />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
