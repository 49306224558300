import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';

export interface ConsultingNumbersProps {
  numbers: Stat[];
}

interface Stat {
  title: string | number;
  subtitle: string;
}

export const ConsultingNumbers: FC<ConsultingNumbersProps> = (props) => {
  const { numbers } = props;
  return (
    <Box>
      <Grid container spacing={2}>
        {numbers.map((item, i) => (
          <Grid key={i} item xs={12} sm={4}>
            <Typography
              variant="h3"
              align={'center'}
              gutterBottom
              sx={{
                fontWeight: 900,
              }}
            >
              {item.title}
            </Typography>
            <Typography color="text.secondary" align={'center'} component="p">
              {item.subtitle}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
